body.sidebar-mini.iframe,
body.iframe {
  margin-top: 15px;

  .content-wrapper {
    margin-left: 0 !important;
  }

  .actions-row {
    margin-bottom: 0 !important;
    float: left;

    .add-document-button {
      padding: 22px 18px;
    }
  }

  .add-category-button,
  .add-document-multiple-button,
  .main-header,
  .main-footer {
    display: none;
  }
}