html,
body {
  height: 100%;
}

html {
  font-size: $font-size-base;
}

body {
  font-size: 1rem;
  // Sticky footer
  display: flex;
  flex-direction: column;
}

.btn,
button,
a {
  transition: $transition-base;
  cursor: pointer;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}