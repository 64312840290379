table.table {
  td.browsable {
    cursor: pointer;
  }
}

.actions-col {
  text-align: right;
  .actions-container {
    min-width: 60px;
    text-align: right;
  }
}

th._custom_Action {
  text-align: right;
}

.main-actions-container .btn {
  margin-bottom: 10px;
}

body {
  min-width: 690px;
}

body.scroll-x,
html.scroll-x,
.wrapper.scroll-x {
  overflow-x: auto;
}