.layout-rgpd {
  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  .card {
    margin-bottom: 0;
  }
}

.login-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    background: rgba($black, 0.8);
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    .img-fluid {
      margin: 5px auto;
      max-width: 110px;
    }
  }

  .wrapper {
    min-height: auto;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card {
    padding: 0;
  }
}