@font-face {
  font-family: 'Blogger';
  src: url('#{$fonts-path}/blogger_sans-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/blogger_sans-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blogger';
  src: url('#{$fonts-path}/blogger_sans-bold-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/blogger_sans-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Blogger';
  src: url('#{$fonts-path}/blogger_sans-medium-webfont.woff2') format('woff2'),
       url('#{$fonts-path}/blogger_sans-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}