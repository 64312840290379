.wrapper {
  // Sticky footer
  display: flex;
  flex-direction: column;

  .content-wrapper {
    min-height: 1px !important;
    // Sticky footer
    flex: 1 0 auto;
    padding-bottom: 45px;
  }
}

.page-title {
  @include media-breakpoint-down(md) {
    font-size: rem(28px);
  }
}

div.dataTables_wrapper {
  .dt-buttons,
  .dataTables_length {
    margin-bottom: 20px;
  }

  .dataTable {
    width: 100% !important;
  }

  .dataTables_length {
    label {
      margin-bottom: 0;
      color: $body-color;
    }
  }

  .dataTables_length {
    float: none;

    @include media-breakpoint-up(md) {
      float: left;
    }
  }

  .dt-buttons {
    float: none;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      float: right;
    }
  }

  .table,
  .dataTables_processing {
    clear: both;
  }

  div.dataTables_info {
    margin-top: 40px;
    text-align: center;
    padding-top: 0;
    font-size: rem(14px);
    color: rgba($body-color, 0.8);
    white-space: normal;
  }

  div.dataTables_paginate {
    margin-top: 10px;
    text-align: center;

    ul.pagination {
      justify-content: center;
    }
  }
}

.table thead th {
  color: $primary;

  &.sorting,
  &.sorting_asc,
  &.sorting_asc_disabled,
  &.sorting_desc,
  &.sorting_desc_disabled {
    // Ouais, la flemme, kay ?
    padding-right: $table-cell-padding !important;
    white-space: nowrap;

    &:before,
    &:after {
      content: none;
    }

    i {
      @extend .fa;
      display: inline-block;
      margin-left: 10px;
      color: lighten($body-color, 20%);
    }
  }

  &.sorting {
    i {
      @extend .fa-arrows-v;
    }
  }

  &.sorting_asc,
  &.sorting_asc_disabled {
    i {
      @extend .fa-sort-alpha-asc;
    }
  }

  &.sorting_desc,
  &.sorting_desc_disabled {
    i {
      @extend .fa-sort-alpha-desc;
    }
  }
}

.form-actions {
  text-align: center;
}

.main-footer {
  padding: 0;
  border: 0;
}

.footer {
  font-size: rem(14px);
  padding: 20px;
  background: rgba($black, 0.8);
  color: rgba($white, 0.8);

  .footer-contact {
    margin-bottom: 1rem;
  }
}

.page-select-template {
  a {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    background: $gray-400;

    div {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px;
      color: $white;
      background: rgba($black, 0.8);
    }
  }
}

.card-buttons {
  .row {
    margin-top: -$grid-gutter-width/2;
    margin-bottom: -$grid-gutter-width/2;
  }

  [class*="col"] {
    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;
  }
}

#MailForm,
#TemplateForm {
  .form-actions {
    .btn {
      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    }
  }
}