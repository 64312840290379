.gjs-pn-devices-c > .gjs-pn-buttons {
  display: none;
}

.gjs-pn-views > .gjs-pn-buttons > .fa-paint-brush,
.gjs-pn-views > .gjs-pn-buttons > .fa-bars,
.gjs-pn-options .gjs-pn-btn.fa-warning,
.gjs-pn-options .gjs-pn-btn.fa-download,
.gjs-pn-options .gjs-pn-btn.fa-code {
  //display: none;
}

.gjs-trt-trait {
  .gjs-field {
    width: 100% !important;
    text-align: left;
    display: flex;
    align-items: center;
    background-color: transparent;

    &:before {
      width: 23%;
    }
  }

  .gjs-label {
    display: none;
  }

  .gjs-input-holder {
    width: 87%;
    background: rgba(0, 0, 0, 0.15);
  }

  .gjs-label[title="Id"] {
    + .gjs-field {
      &:before {
        content: 'Id';
      }
    }
  }

  .gjs-label[title="Title"] {
    + .gjs-field {
      &:before {
        content: 'Titre';
      }
    }
  }

  .gjs-label[title="Alt"] {
    + .gjs-field {
      &:before {
        content: 'Alt';
      }
    }
  }

  .gjs-label[title="Href"] {
    + .gjs-field {
      &:before {
        content: 'Lien';
      }
    }
  }

  .gjs-label[title="Target"] {
    + .gjs-field {
      &:before {
        content: 'Cible';
      }
    }
  }
}

.gjs-am-add-asset {
  .gjs-am-add-field {
    width: 60% !important;
  }

  button {
    width: 35% !important;
  }
}