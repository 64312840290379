.brighttheme-notice {
  background-color: theme-color-level('warning', $alert-bg-level);
  border: 1px solid theme-color-level('warning', $alert-border-level);

  &,
  .ui-pnotify-title {
    color: theme-color-level('warning', $alert-color-level);
  }
}

.brighttheme-info {
  background-color: theme-color-level('info', $alert-bg-level);
  border: 1px solid theme-color-level('info', $alert-border-level);

  &,
  .ui-pnotify-title {
    color: theme-color-level('info', $alert-color-level);
  }
}

.brighttheme-success {
  background-color: theme-color-level('success', $alert-bg-level);
  border: 1px solid theme-color-level('success', $alert-border-level);

  &,
  .ui-pnotify-title {
    color: theme-color-level('success', $alert-color-level);
  }
}

.brighttheme-error {
  background: theme-color-level('danger', $alert-bg-level);
  border: 1px solid theme-color-level('danger', $alert-border-level);

  &,
  .ui-pnotify-title {
    color: theme-color-level('danger', $alert-color-level);
  }
}

.brighttheme-icon-info,
.brighttheme-icon-notice,
.brighttheme-icon-success {
  margin-top: 4px;
}

.brighttheme-icon-error {
  margin-top: 3px;
}