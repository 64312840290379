.icon-explain {
  display: none;
}

.btn {
  text-transform: uppercase;
  font-weight: 500;
}

.card {
  padding: 1rem;
}

.btn-primary {
  color: $white;
  background: linear-gradient(to right, $gradient-red, $gradient-orange);

  &:hover {
    background: linear-gradient(to right, lighten($gradient-red, 10%), lighten($gradient-orange, 10%));
  }
}


.custom-file-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 18%;
}

.custom-control-label {
  min-height: 1rem;
}

.nowrap {
  white-space: nowrap;
}

.table tr,
.list-group-item {
  &.empty {
    transition: $transition-base;
  }

  + .empty {
    display: none !important;
  }
}

.page-link {
  @include media-breakpoint-down(sm) {
    padding: .25rem .5rem;
  }
}

.medium-cb {
  width: 15px;
  height: 15px;
}

#delete_documents {
  color: White;
}

#uploadbar,
#uploadbar-multiple {
  margin: 0 20px 20px 20px;
  width: 400px;
  height: 8px;
  position: relative;
}

#addEditDocumentForm .select2-container--default .select2-selection--single, .select2-selection .select2-selection--single {
  border-color: #b8b8b8;
}

#addEditDocumentForm #document_description {
  border-color: #b8b8b8 !important;
}

.error-validation ul li {
  list-style-type: none;
}